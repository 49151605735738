import React, {useEffect} from "react"
import Helmet from "react-helmet"
import { ToastContainer } from 'react-toastify';
import Navbar from "./Navbar"
import Footer from "./Footer"
import "bootstrap/dist/css/bootstrap.css"
import "react-toastify/dist/ReactToastify.min.css";
import "./index.css"
import "./overrideBootstrap.css"
import {saveSearchParams} from "../storage"

export default ({
  bodyClassName,
  footerClassName,
  children,
  bodyID,
}) => {
  useEffect(() => {
    if (!(window || {}).location) {
      return
    }

    saveSearchParams(window.location.search)
  }, [])

  return (
    <>
      <Helmet bodyAttributes={{class: bodyClassName, id: bodyID}}/>
      <Navbar/>
      {children}
      <ToastContainer/>
      <Footer className={footerClassName}/>
    </>
  )
}
