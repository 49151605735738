import React from 'react'
import {Button, Col, Form} from "react-bootstrap"
import FormikInputField from "../checkout/FormikInputField"

const FormView = ({formik}) => (
  <Form onSubmit={formik.handleSubmit} className="form-wrapper verify-form">
    <Form.Row>
      <Col sm={12} md={12}>
        <Form.Group>
          <FormikInputField
            name="name"
            label="Name"
            type="text"
            {...formik}
          />
        </Form.Group>
      </Col>
    </Form.Row>
    <Form.Row>
      <Col sm={12} md={12}>
        <Form.Group>
          <FormikInputField
            name="email"
            label="Email"
            type="text"
            {...formik}
          />
        </Form.Group>
      </Col>
    </Form.Row>
    <Form.Row>
      <Col sm={12} md={12}>
        <Form.Group>
          <FormikInputField
            name="company"
            label="Company"
            type="text"
            {...formik}
          />
        </Form.Group>
      </Col>
    </Form.Row>
    <Form.Row>
      <Col sm={12} md={12}>
        <Form.Group>
          <FormikInputField
            name="website"
            label="Website"
            type="text"
            {...formik}
          />
        </Form.Group>
      </Col>
    </Form.Row>
    <Form.Row>
      <Col sm={12} md={12}>
        <Form.Group>
          <FormikInputField
            name="message"
            label="Message"
            attrs={{
              as: `textarea`,
              size: `sm`,
              placeholder: `Please describe your use case for using Spider.com (one or two Sentences please).`,
              className: `useCaseDescriptionField`,
            }}
            {...formik}
          />
        </Form.Group>
      </Col>
    </Form.Row>
    <Form.Row>
      <Col sm={12} md={12} className="mt-10 text-center">
        <Form.Group>
          <Button type="submit" variant="round-green" className="btn-login">
            Send request
          </Button>
        </Form.Group>
      </Col>
    </Form.Row>
  </Form>
)

export default FormView
