import React from "react"
import {
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  ApolloClient,
  from,
} from "@apollo/client"
import fetch from "isomorphic-fetch"

import {settings} from "./config"
import {RetryLink} from "@apollo/client/link/retry"

const cache = new InMemoryCache({addTypename: false})

const httpLink = new HttpLink({
  uri: settings.graphqlURL,
  fetch,
})

const retryLink = new RetryLink({attempts: {max: 2}})
const link = from([retryLink, httpLink])

const apolloClient = () =>
  new ApolloClient({
    link,
    cache,
  })

export const client = apolloClient()

export default ({children}) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)
