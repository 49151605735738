import * as queryString from "query-string"

const CONV_INFO_NAME = `conversion`

export const saveSearchParams = (params) => {
  const conversion = getConversionParams()
  const query = queryString.parse(params)
  if (query.gclid) {
    conversion.clkid = query.gclid;
    conversion.source = 'google';
    conversion.campaign = query.campaign;
    conversion.keyword = query.keyword;
  }

  if (query.msclkid) {
    conversion.clkid = query.msclkid;
    conversion.source = 'bing';
    conversion.campaign = query.utm_campaign;
    conversion.keyword = query.utm_term;
  }

  localStorage.setItem(CONV_INFO_NAME,  JSON.stringify(conversion))
}

export const getConversionParams = () =>
  JSON.parse(
    localStorage.getItem(CONV_INFO_NAME) ||
    `{"clkid":"", "source":"", "campaign":"", "keyword":""}`
  )
