import React from "react"
import EnrichmentAPIDemo from "./enrichment-api"
import { Col, Container, Row } from "react-bootstrap"
import Helmet from "react-helmet"

import "./index.css"

export default () => (
  <div>
    <Helmet
      title="Enrichment API Live Demo | Spider.com"
      bodyAttributes={{ class: `pt-0` }}
    />
    <div className="slider pt-50 pb-50">
      <Container>
        <Row className="text-center">
          <Col>
            <h1 className="c-white mb-0">Enrichment API Live Demo</h1>
            <p className="c-bluish text-14 mb-10">
              The Enrichment API lets you retrieve social information associated
              with an email address, such as a person’s name and location.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <EnrichmentAPIDemo />
          </Col>
        </Row>
      </Container>
    </div>
  </div>
)
