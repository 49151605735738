export const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1)

export const nameByDomain = (domain) =>
  domain === DOMAINS.Bing.value ? DOMAINS.Bing.name : DOMAINS.Google.name

export const DOMAINS = {
  Google: {
    name: `Google`,
    value: `www.google.com`,
  },
  Bing: {
    name: `Bing`,
    value: `www.bing.com`,
  },
}

export const PRODUCTS = {
  Serp: `SERP`,
  Suggest: `Search Complete`,
}

export const FORMAT = {
  CSV: `csv`,
  JSON: `json`,
}
