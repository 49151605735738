import React, { useState, useEffect } from "react"
import SERPForm from "./SERPForm"
import { Formik } from "formik"
import * as Yup from "yup"
import { Row } from "react-bootstrap"
import "./SERPCrawler.css"
import { gql, useApolloClient } from "@apollo/client"
import classnames from "classnames"
import objectHash from "object-hash"
import SearchResponse from "./SearchResponse"
import { FORMAT, nameByDomain, PRODUCTS } from "./utils"
import { encodeGeotarget, formatGeotarget } from "./format"
import { default as SyntaxHighlighter } from "react-syntax-highlighter"
import { androidstudio as theme } from "react-syntax-highlighter/dist/cjs/styles/hljs"
import SerpLoader from "./SerpLoader"

const SERP_QUERY = gql`
  query RealTimeSERP($input: SERPInput!) {
    serp(input: $input) {
      body
      json
    }
  }
`

const GEOTARGET_QUERY = gql`
  query findTargets($input: String!) {
    findTargets(name: $input) {
      canonicalName
      countryCode
      population
    }
  }
`

const AUTOCOMPLETE_QUERY = gql`
  query searchComplete($input: SearchCompleteInput!) {
    suggestions: searchComplete(input: $input)
  }
`

export default () => {
  const [suggestions, setSuggestions] = useState([])
  const [product, setProduct] = useState(PRODUCTS.Serp)
  const [geotarget, setGeotarget] = useState({
    canonicalName: `Los Angeles,California,United States`,
    countryCode: `us`,
    population: 4000000,
  })
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [params, setParams] = useState({
    query: `vegan near me`,
    domain: `www.google.com`,
    userAgent: `desktop`,
  })
  const [serp, setSerp] = useState({
    [objectHash(params)]: null,
  })
  const client = useApolloClient()
  useEffect(() => {
    handleSubmit(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const formatError = (error) => {
    if (error) {
      console.error(error)
    }
    if (error === `too many requests`) {
      return `Please wait a few seconds before repeating your request`
    }

    return `Oops.. something went wrong. Our team is notified already and working on a resolution`
  }

  const fetchSERP = ({
    domain,
    query,
    userAgent,
    canonicalName,
  }) => {
    setIsLoading(true)
    setError(null)
    return client
      .query({
        query: SERP_QUERY,
        variables: {
          input: {
            domain,
            query,
            canonicalName,
            userAgent,
          },
        },
      })
      .then(({ data: { serp: response } }) => {
        const newP = { ...params, domain, query, userAgent }
        setParams(newP)
        const cleanResponse = {
          json: {
            metadata: {
              q: query,
              engine: nameByDomain(domain),
              device: userAgent,
              locale: canonicalName,
            },
            ...JSON.parse(response.json),
          },
          body: cleanConsent(response.body),
        }
        const isNewAgent =
          objectHash({ domain, query, canonicalName }) ===
            objectHash({
              domain: params.domain,
              query: params.query,
              canonicalName: geotarget.canonicalName,
            }) && userAgent !== params.userAgent
        const newSerp = isNewAgent
          ? { ...serp, ...{ [objectHash(newP)]: cleanResponse } }
          : { [objectHash(newP)]: cleanResponse }
        setSerp(newSerp)
        // postpone response in case it was cached
        setTimeout(() => {
          setIsLoading(false)
        }, 200)
      })
      .catch((e) => {
        setError(e)
        setIsLoading(false)
      })
  }

  const handleSubmit = ({ domain, query }) => {
    if (product === PRODUCTS.Serp) {
      return fetchSERP({
        domain,
        query,
        canonicalName: geotarget.canonicalName,
        userAgent: params.userAgent,
      })
    } else {
      return handleSuggest({ domain, query })
    }
  }

  const loadTargets = (v, callback) =>
    client
      .query({ query: GEOTARGET_QUERY, variables: { input: v } })
      .then(({ data: { findTargets } }) =>
        callback(
          findTargets.map((t) => {
            return {
              value: encodeGeotarget(t),
              label: formatGeotarget(t),
            }
          })
        )
      )

  const selectUserAgent = ({ value }) => {
    const newP = { ...params, userAgent: value }
    const currentSerp = serp[objectHash(newP)]
    if (currentSerp) {
      setParams(newP)
      return Promise.resolve()
    }

    return fetchSERP({ ...newP, canonicalName: geotarget.canonicalName })
  }

  const cleanConsent = (innerHTML) => {
    innerHTML = innerHTML
      .replace(`id="lb"`, `id="lb" style="display:none"`)
      .replace(`url(/`, `url(https://www.google.com/`)
      .replace(`src="/`, `src="https://www.google.com/`)
    return innerHTML
  }

  const handleParamChange = (name, value) =>
    fetchSERP({
      ...params,
      canonicalName: geotarget.canonicalName,
      [name]: value,
    })

  const handleSuggest = ({ query, domain }) => {
    setIsLoading(true)
    return client
      .query({
        query: AUTOCOMPLETE_QUERY,
        variables: { input: { query: query, domain: domain } },
      })
      .then(({ data: { suggestions } }) => setSuggestions(suggestions))
      .catch(setError)
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false)
        }, 200)
      })
  }

  const handleGeoChange = (value) => {
    setGeotarget(value)
    return fetchSERP({ ...params, ...value })
  }

  const data = serp[objectHash(params)]
  return (
    <div className="serp-body">
      <h3>Spider® Real-Time Crawler</h3>
      <p className="serp-subtitle">
        Try out our Live Demo. Scrape any data from Google with Spider® SERP API
      </p>
      <Formik
        onSubmit={handleSubmit}
        initialValues={params}
        validationSchema={Yup.object().shape({
          query: Yup.string().required(`Required`),
          domain: Yup.string(),
        })}
        validateOnBlur={false}
      >
        {(props) => (
          <SERPForm
            handleProductChange={(value) => {
              handleSuggest(params)
              setProduct(value)
            }}
            geotarget={geotarget}
            onGeoChange={handleGeoChange}
            loadTargets={loadTargets}
            handleParamChange={handleParamChange}
            {...props}
          />
        )}
      </Formik>
      <Row
        className={classnames(`content justify-content-center`, {
          error: !!error,
          loading: !!isLoading,
        })}
      >
        {isLoading ? (
          <SerpLoader />
        ) : error ? (
          <span className="error-message">{formatError(error.message)}</span>
        ) : (
          <>
            {product === PRODUCTS.Serp && (
              <SearchResponse
                domain={params.domain}
                userAgent={params.userAgent}
                selectUserAgent={selectUserAgent}
                html={data.body}
                formats={[FORMAT.CSV, FORMAT.JSON]}
                json={data.json}
              />
            )}
            {product === PRODUCTS.Suggest && (
              <SyntaxHighlighter
                className="pt-3 w-100 h-100 m-0 text-left"
                style={theme}
                language="javascript"
                showLineNumbers
              >
                {JSON.stringify(suggestions, null, 2)}
              </SyntaxHighlighter>
            )}
          </>
        )}
      </Row>
    </div>
  )
}
