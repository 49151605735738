import React, { useState } from "react"
import { gql, useApolloClient } from "@apollo/client"
import { Formik } from "formik"
import * as Yup from "yup"
import { Container, Row, Col } from "react-bootstrap"
import classnames from "classnames"
import EnrichmentForm from "./enrichment-form"
import SpinnerCenter from "../SpinnerCenter"
import SearchResponse from "./search-response"

import "./enrichment-api.css"

const ENRICHMENT_QUERY = gql`
  query Enrichment($input: String!) {
    enrichment(input: $input) {
      person {
        email
        thumbnailURL
        displayName
        givenName
        familyName
        formattedName
        location
      }
      company {
        name
        description
        country
        state
        city
        zipCode
        founded
        revenue
        employeeCount
        totalFunding
      }
    }
  }
`

export default () => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const client = useApolloClient()

  const fetchEnrichment = ({ email }) => {
    if (isLoading) {
      return Promise.resolve()
    }

    setIsLoading(true)
    setError(null)
    return client
      .query({
        query: ENRICHMENT_QUERY,
        variables: {
          input: email,
        },
        fetchPolicy: `no-cache`,
      })
      .then(({ data: { enrichment } }) => {
        setData(enrichment)
      })
      .catch(setError)
      .finally(() => {
        setIsLoading(false)
      })
  }
  const formatError = (error) => {
    console.log(error)
    if (error === `too many requests`) {
      return `Please wait a few seconds before repeating your request`
    }

    return `Oops.. something went wrong. Our team is notified already and working on a resolution`
  }

  return (
    <Container>
      <Row className="justify-content-center mt-40 mb-30">
        <Col md={8} xs={12} lg={8}>
          <Formik
            onSubmit={fetchEnrichment}
            initialValues={{ email: `` }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required(`Required`),
            })}
            validateOnChange
            validateOnBlur
          >
            {(props) => <EnrichmentForm isLoading={isLoading} {...props} />}
          </Formik>
        </Col>
      </Row>
      <Row
        className={classnames(`justify-content-center`, {
          error: !!error,
          loading: !!isLoading,
        })}
      >
        {isLoading ? (
          <SpinnerCenter />
        ) : error ? (
          <span className="enrichment-api--error-message">
            {formatError(error.message)}
          </span>
        ) : !data ? null : (
          <SearchResponse data={data} />
        )}
      </Row>
    </Container>
  )
}
