import React from "react"
import SERPCrawler from "./SERPCrawler"
import { Col, Container, Row } from "react-bootstrap"
import Helmet from "react-helmet"
import "./index.css"

export default () => (
  <div>
    <Helmet
      title="SERP API Live Demo | Spider.com"
      bodyAttributes={{ class: `pt-0` }}
    />
    <div className="pt-50 pb-50">
      <Container className="mt-50">
        <Row className="text-center justify-content-center">
          <Col md={10} lg={10}>
            <div className="serp-header">
              <div>
                <div className="dots" />
                <div className="dots" />
                <div className="dots" />
              </div>
            </div>
            <SERPCrawler />
          </Col>
        </Row>
      </Container>
    </div>
  </div>
)
