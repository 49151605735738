import React, {useEffect} from 'react'
import Head from "../../layout/Head"
import Layout from "../../layout"
import {Col, Container, Row} from "react-bootstrap"
import SignupForm from "./signupForm"
import {Formik} from "formik"
import * as Yup from "yup"
import {gql, useMutation} from "@apollo/client"
import {Slide, toast} from 'react-toastify'
import {settings} from "../../config"

const CREATE_SIGNUP_ENTRY = gql`
  mutation($input: SignupRequestInput!) {
    signupRequest(input: $input)
  }
`

export default () => {
  const [createSignupEntry] = useMutation(CREATE_SIGNUP_ENTRY)
  const handleSubmit = async (values, formik) => {
    formik.setSubmitting(true)
    const {name, email, company, website, message} = values
    await createSignupEntry({
      variables: {
        input: {
          name,
          email,
          company,
          website,
          message,
        },
      },
    })
      .then(({data: {signupRequest}}) => {
        if (!!signupRequest) {
          toast.info("Your request has been recorded. Thank you for interest in spider!", {
            position: "bottom-right",
            autoClose: 3000,
            closeOnClick: true,
            transition: Slide,
          })
        } else {
          toast.error('Oops, we could not record your request. Please try again later', {
            position: "bottom-right",
            autoClose: 3000,
            closeOnClick: true,
            transition: Slide,
          })
        }
        },
      )
      .catch(() => {
        toast.error('Oops, we could not record your request. Please try again later', {
          position: "bottom-right",
          autoClose: 3000,
          closeOnClick: true,
          transition: Slide,
        })
      })
      .finally(() => {
        setTimeout(() => {
          formik.setSubmitting(false)
        }, 2000)
      })
  }

  return (
    <>
      <Head title="Signup Form"/>
      <Layout bodyClassName="b-blue">
        <main role="main" className="checkout-pages">
          <Container className="form-container mt-10 mb-10">
            <Row className="justify-content-center mt-30 text-center">
              <Col xs={12} md={12} lg={12}>
                <h4>We are currently accepting potential new customers on an invite only basis. </h4>
                <h5>Please provide your name, number, email, and description of what service you are looking for.</h5>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col lg={5} md={8} >
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    company: "",
                    website: "",
                    message: "",
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(`Required`),
                    email: Yup.string().email().required(`Required`),
                    company: Yup.string().required(`Required`),
                    website: Yup.string().required(`Required`),
                    message: Yup.string().max(2000).required(`Required`),
                  })}
                  onSubmit={handleSubmit}
                >
                  {(formik) => <SignupForm formik={formik}/>}
                </Formik>
              </Col>
            </Row>
          </Container>
        </main>
      </Layout>
    </>
  )
}
