import React from "react"
import { Form, InputGroup, FormControl, Button } from "react-bootstrap"
import iconSearch from "../../images/icon-search.svg"
import AsyncSelect from "react-select/async"
import "./SERPForm.css"
import { DOMAINS, PRODUCTS } from "./utils"
import { decodeGeotarget, encodeGeotarget, formatGeotarget } from "./format"

export default ({
  values,
  geotarget,
  onGeoChange,
  handleProductChange,
  handleParamChange,
  handleSubmit,
  handleChange,
  loadTargets,
}) => (
  <Form className="row-serp-inputs" onSubmit={handleSubmit}>
    <InputGroup className="serp-search">
      <FormControl
        name="query"
        type="text"
        defaultValue={values.query}
        onChange={handleChange}
        className="search-input"
        placeholder="Search using a keyword"
        required
      />
    </InputGroup>
    <InputGroup className="serp-location">
      <div className="b-left w-100">
        <AsyncSelect
          cacheOptions
          defaultValue={{
            value: encodeGeotarget(geotarget),
            label: formatGeotarget(geotarget),
          }}
          loadOptions={loadTargets}
          styles={{
            option: (styles) => {
              return {
                ...styles,
                backgroundColor: `white`,
              }
            },
            container: (styles) => {
              return {
                ...styles,
                zIndex: 300,
              }
            },
            indicatorsContainer: (styles) => {
              return {
                ...styles,
                display: `none`,
              }
            },
          }}
          onChange={(e) => onGeoChange(decodeGeotarget(e.value))}
          className="uule-input"
          search
        />
      </div>
    </InputGroup>
    <InputGroup className="serp-type align-content-center justify-content-center">
      <div className="b-left">
        <FormControl
          name="productSelect"
          as="select"
          defaultValue={values.product}
          default={values.product}
          onChange={(e) => handleProductChange(e.target.value)}
          required
        >
          {[PRODUCTS.Serp, PRODUCTS.Suggest].map((p) => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
        </FormControl>
      </div>
    </InputGroup>
    <InputGroup className="serp-engine align-content-center justify-content-center">
      <div className="b-left">
        <FormControl
          name="domainSelect"
          as="select"
          defaultValue={values.domain}
          default={values.domain}
          onChange={(e) => handleParamChange(`domain`, e.target.value)}
          required
        >
          {[DOMAINS.Google, DOMAINS.Bing].map((d) => (
            <option key={d.value} value={d.value}>
              {d.name}
            </option>
          ))}
        </FormControl>
      </div>
    </InputGroup>
    <InputGroup className="btn-serp-search align-content-center justify-content-center">
      <Button
        type="submit"
        className="btn-search"
        id="btn-crawler"
        variant="outline-secondary"
      >
        <img alt="" src={iconSearch} />
        TEST SEARCH
      </Button>
    </InputGroup>
  </Form>
)
