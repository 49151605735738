import React, { useState } from "react"
import { Col } from "react-bootstrap"
import ViewportSelect from "./ViewportSelect"
import FormatSelect from "./FormatSelect"
import { DOMAINS, FORMAT } from "./utils"
import { default as SyntaxHighlighter } from "react-syntax-highlighter"
import { androidstudio as theme } from "react-syntax-highlighter/dist/cjs/styles/hljs"
import { parse } from "json2csv"
import "./SearchResponse.css"

export default ({
  domain,
  userAgent,
  formats,
  selectUserAgent,
  html,
  json,
}) => {
  const [format, setFormat] = useState(FORMAT.JSON)
  const parseCSV = (json) => {
    if (domain === DOMAINS.Google.value && json.organicItems) {
      return parse(json.organicItems, {
        fields: [`position`, `title`, `url`, `description`],
      })
    }

    return parse(json.item, {
      fields: [`position`, `title`, `url`, `description`],
    })
  }

  return (
    <>
      <Col className="result" md={6} xs={12} lg={6}>
        <ViewportSelect defaultValue={userAgent} onChange={selectUserAgent} />
        <div className="iframe-container pt-3">
          <iframe
            id="frame"
            title="Search engine result"
            frameBorder="0"
            scrolling="no"
            srcDoc={html}
          />
        </div>
      </Col>
      <Col className="result code-container" md={6} xs={12} lg={6}>
        <FormatSelect
          defaultValue={FORMAT.JSON}
          options={formats}
          onChange={({ value }) => setFormat(value)}
        />
        {format === FORMAT.JSON && (
          <SyntaxHighlighter
            className="pt-3 mt-0 text-left"
            style={theme}
            language="json"
            showLineNumbers
          >
            {JSON.stringify(json, null, 2)}
          </SyntaxHighlighter>
        )}
        {format === FORMAT.CSV && (
          <SyntaxHighlighter
            className="pt-3 mt-0 text-left"
            style={theme}
            language="plaintext"
            showLineNumbers
          >
            {parseCSV(json)}
          </SyntaxHighlighter>
        )}
      </Col>
    </>
  )
}
