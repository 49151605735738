import React from "react"
import Form from "react-bootstrap/Form"

export default ({
  name,
  label,
  type,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleFocus,
  readOnly,
  attrs,
}) => (
  <>
    <Form.Label>{label}</Form.Label>
    <Form.Control
      type={type}
      name={name}
      value={values[name]}
      isInvalid={touched[name] && errors[name]}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      readOnly={readOnly}
      {...attrs}
    />
    <Form.Control.Feedback type="invalid" className="text-uppercase">
      {errors[name]}
    </Form.Control.Feedback>
  </>
)
