import React from "react"
import { Form, InputGroup, FormControl } from "react-bootstrap"
import iconSearch from "../../images/icon-search.svg"

import "./enrichment-form.css"

export default ({
  values,
  isLoading,
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  touched,
}) => (
  <Form onSubmit={handleSubmit}>
    <Form.Row className="">
      <InputGroup className="enrichment-form--input-group">
        <FormControl
          name="email"
          type="text"
          defaultValue={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          className="enrichment-form--input"
          placeholder="Type an email address here..."
          isInvalid={!!errors && !!errors.email}
          disabled={isLoading}
          required
        />
        <InputGroup.Append>
          <button
            className="btn btn-search btn-light enrichment-form--submit"
            disabled={(!!errors && !!errors.email) || isLoading}
            type="submit"
          >
            <img alt="Click to search" src={iconSearch} />
          </button>
        </InputGroup.Append>
      </InputGroup>
      <FormControl.Feedback
        type="invalid"
        className="enrichment-form--feedback"
      >
        {errors &&
          errors.email &&
          touched &&
          touched.email &&
          !isLoading &&
          `Please provide a valid email address`}
      </FormControl.Feedback>
    </Form.Row>
  </Form>
)
