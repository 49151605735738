import React from "react"
import { Link } from "react-router-dom"
import logo from "../images/logo_spider_white_noweb.svg"

export default ({ className }) => (
  <footer className={`pt-70 ${className}`}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4 col-sm-4 box-1">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Spider" />
          </Link>
        </div>
      </div>
      <div className="row footer-bottom pt-20 pb-10 mt-30">
        <p className="text-14 c-white text-center">
          <b>{new Date().getFullYear()} © SPIDER LLC</b>
        </p>
      </div>
    </div>
  </footer>
)
