import React from "react"
import { Link } from "react-router-dom"
import Navbar from "react-bootstrap/Navbar"
import logo from "../images/logo_spider.svg"

export default () => (
  <Navbar fixed="top" expand="md" variant="light">
    <Navbar.Brand as="span">
      <Link to="/">
        <img src={logo} alt="Spider" />
      </Link>
    </Navbar.Brand>
  </Navbar>
)
