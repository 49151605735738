import React from "react"
import Select from "react-select"
import "./FormatSelect.css"

const styles = {
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      display: `none`,
    }
  },
  singleValue: (styles) => {
    return {
      ...styles,
      color: `#D8EBFF`,
    }
  },
  control: (styles) => {
    return {
      ...styles,
      backgroundColor: `#174A7D`,
      border: `none`,
      borderRadius: 0,
      borderBottomLeftRadius: `5px`,
      borderStyle: `solid`,
      borderBottomWidth: `2px`,
      borderLeftWidth: `2px`,
      borderRightWidth: `2px`,
      boxShadow: `initial`,
      ":hover": {
        boxShadow: `initial`,
      },
    }
  },
}

export default ({ onChange, defaultValue, options }) => (
  <Select
    id="formatSelect"
    defaultValue={{
      value: defaultValue,
      label: defaultValue.toUpperCase(),
    }}
    options={options.map((x) => {
      return { value: x, label: x.toUpperCase() }
    })}
    onChange={onChange}
    styles={styles}
  />
)
