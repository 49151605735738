import React from "react"
import Suspended from "./components/suspended"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import ApolloProvider from "./apollo"
import _trimStart from "lodash/trimStart"

import ReactGA from "react-ga"
import {settings} from "./config"
import SERPCrawler from "./components/serp-demo"
import EnrichmentAPIDemo from "./components/enrichment-demo"

ReactGA.initialize(`UA-120543404-1`)

function App() {
  return (
    <ApolloProvider>
      <Router>
        <Switch>
          <Route path="/signup">
            <Suspended />
          </Route>
          <Route path="/serp">
            <SERPCrawler />
          </Route>
          <Route path="/enrichment">
            <EnrichmentAPIDemo />
          </Route>
          <Route
            path="/"
            component={() => {
              window.location.replace(
                  settings.wwwURL + _trimStart(window.location.pathname, `/`)
              )
              return null
            }}
          />
          <Route component={Suspended} />
        </Switch>
      </Router>
    </ApolloProvider>
  )
}

export default App
