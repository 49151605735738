import _ from "lodash"
import React from "react"
import classnames from "classnames"
import { Container, Row, Col } from "react-bootstrap"
import formatCurrency from "format-currency"

import "./search-response.css"

function Field(props) {
  return (
    <React.Fragment>
      <smal className="text-secondary enrichment-serach-response--field-tip">
        {props.header}
      </smal>
      <p className="enrichment-serach-response--field">{props.value}</p>
    </React.Fragment>
  )
}

const CURRENCY_OPTS = { format: `%s%v %c`, code: `USD`, symbol: `$` }

const fromData = (input) => ({
  person: {
    name: _.get(input, `person.displayName`, `-`),
    email: _.get(input, `person.email`, `-`),
    thumbnailURL: _.get(input, `person.thumbnailURL`, ``),
    location: _.get(input, `person.location`, `-`),
    isEmpty:
      !_.get(input, `person.displayName`) && !_.get(input, `person.location`),
  },
  company: {
    name: _.get(input, `company.name`, `-`),
    description: _.get(input, `company.description`, `-`),
    country: _.get(input, `company.country`, `-`),
    state: _.get(input, `company.state`, `-`),
    city: _.get(input, `company.city`, `-`),
    zipCode: _.get(input, `company.zipCode`, `-`),
    founded: _.get(input, `company.founded`, `-`),
    revenue: _.get(input, `company.revenue`, `0`),
    employeeCount: _.get(input, `company.employeeCount`, `0`),
    totalFunding: _.get(input, `company.totalFunding`, `0`),
    isEmpty:
      !_.get(input, `company.name`) &&
      !_.get(input, `company.description`) &&
      !_.get(input, `company.country`) &&
      !_.get(input, `company.state`) &&
      !_.get(input, `company.city`) &&
      !_.get(input, `company.zipCode`) &&
      !_.get(input, `company.founded`) &&
      !_.get(input, `company.revenue`) &&
      !_.get(input, `company.employeeCount`) &&
      !_.get(input, `company.totalFunding`),
  },
})

export default ({ data }) => {
  const d = fromData(data)

  if (!d.person.thumbnailURL && d.person.isEmpty && d.company.isEmpty) {
    return (
      <p className="c-bluish text-14 mb-10">
        Sorry, this email address is not in out database yet. We are actively
        collecting new profiles. Please try again within a couple of hours.
      </p>
    )
  }

  return (
    <Col
      xs={12}
      md={7}
      lg={7}
      className="enrichment-serach-response--container p-0 m-0"
    >
      <Container className="m-0 pt-3 pb-4">
        {(d.person.thumbnailURL || !d.person.isEmpty) && (
          <React.Fragment>
            <Row className="mb-4 enrichment-serach-response--header">
              <h3 className="px-4">Person</h3>
            </Row>
            <Row className="px-5">
              <Col
                xs={12}
                md={6}
                className="enrichment-serach-response--avatar"
              >
                <img
                  src={
                    d.person.thumbnailURL || `https://www.gravatar.com/avatar`
                  }
                  alt=""
                />
              </Col>
              <Col xs={12} md={6}>
                {d.person.isEmpty && (
                  <p className="enrichment-serach-response--not-found">
                    No metadata found.
                  </p>
                )}
                {!d.person.isEmpty && (
                  <React.Fragment>
                    <Field header="Email" value={d.person.email} />
                    <Field header="Name" value={d.person.name} />
                    <Field header="Location" value={d.person.location} />
                  </React.Fragment>
                )}
              </Col>
            </Row>
          </React.Fragment>
        )}
        {!d.company.isEmpty && (
          <React.Fragment>
            <Row
              className={classnames(
                `mb-4 enrichment-serach-response--header`,
                (d.person.thumbnailURL || !d.person.isEmpty) && `mt-5`
              )}
            >
              <h3 className="px-4">Company</h3>
            </Row>
            <Row className="px-3">
              <Col xs={12}>
                <Field header="Name" value={d.company.name} />
                <Field header="Description" value={d.company.description} />
              </Col>
            </Row>
            <Row className="px-3">
              <Col xs={12} md={6}>
                <Field header="Country" value={d.company.country} />
                <Field header="State" value={d.company.state} />
                <Field header="City" value={d.company.city} />
                <Field header="Zip Code" value={d.company.zipCode} />
              </Col>
              <Col xs={12} md={6}>
                <Field header="Founded" value={d.company.founded} />
                <Field
                  header="Revenue"
                  value={formatCurrency(d.company.revenue, CURRENCY_OPTS)}
                />
                <Field
                  header="Employee Count"
                  value={d.company.employeeCount}
                />
                <Field
                  header="Total funding"
                  value={formatCurrency(d.company.totalFunding, CURRENCY_OPTS)}
                />
              </Col>
            </Row>
            <Row className="px-3">
              <Col>
                <p className="mt-3 enrichment-serach-response--not-found">
                  and more...
                </p>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Container>
    </Col>
  )
}
