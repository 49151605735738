import React from "react"
import "./SerpLoader.css"
import {Col, Row} from "react-bootstrap"
import Lottie from 'react-lottie-player'
import config from "./spider-crawler-loading2.json"

export default () => (
    <Row className="w-100 pt-3 result-row">
        <Col className="loader">
            <h4 className="fetching pt-3 pl-4">Fetching results</h4>
            <Lottie
                animationData={config}
                loop
                play
            />
        </Col>
    </Row>
)
