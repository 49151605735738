import React from "react"
import Select from "react-select"
import { capitalize } from "./utils"
import "./ViewportSelect.css"

const styles = {
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      display: `none`,
    }
  },
  singleValue: (styles) => {
    return {
      ...styles,
      color: `#ffffff`,
    }
  },
  control: (styles) => {
    return {
      ...styles,
      backgroundColor: `#174A7D`,
      border: `none`,
      borderRadius: 0,
      borderBottomLeftRadius: `5px`,
      borderStyle: `solid`,
      borderBottomWidth: `2px`,
      borderRightWidth: `2px`,
      boxShadow: `initial`,
      ":hover": {
        boxShadow: `initial`,
      },
    }
  },
}

export default ({ defaultValue, onChange }) => (
  <Select
    id="userAgent"
    defaultValue={{
      value: defaultValue,
      label: capitalize(defaultValue),
    }}
    options={[`desktop`, `tablet`, `mobile`].map((x) => {
      return { value: x, label: capitalize(x) }
    })}
    onChange={onChange}
    styles={styles}
  />
)
