import getUnicodeFlagIcon from "country-flag-icons/unicode"
import { formatK } from "../../format"

export const encodeGeotarget = (geotarget) =>
  `${geotarget.countryCode}_${geotarget.canonicalName}`

export const decodeGeotarget = (value) => {
  return {
    canonicalName: value.split(`_`)[1],
    countryCode: value.split(`_`)[0],
  }
}

const formatPopulation = ({ population }) => {
  if (!population || population <= 0) {
    return ``
  }

  return `(pop: ${formatK(population)})`
}

export const formatGeotarget = (t) =>
  `${getUnicodeFlagIcon(t.countryCode)} ${t.canonicalName} ${formatPopulation(
    t
  )}`
