export const config = {
  description: `Scrape any website with no blocking. Commercial service that delivers 15M IP addresses`,
  author: `spider.com`,
}

const plans = [
  {
    slug: `free`,
    name: `Free`,
    quota: `50 MB`,
    price: 0,
    perGB: 0,
  },
  {
    slug: `micro`,
    name: `Micro`,
    quota: `5 GB`,
    price: 75,
    perGB: 15,
  },
  {
    slug: `starter`,
    name: `Starter`,
    quota: `40 GB`,
    price: 480,
    perGB: 12,
  },
  {
    slug: `professional`,
    name: `Professional`,
    quota: `100 GB`,
    price: 850,
    perGB: 8.5,
  },
  {
    slug: `spiderman`,
    name: `Spiderman`,
    quota: `1000 GB`,
    price: 2500,
    perGB: 2.5,
  },
]

const serpPlans = [
  {
    slug: `independent`,
    name: `Independent`,
    quota: `49000 pages`,
    price: 59,
  },
  {
    slug: `harvester`,
    name: `Harvester`,
    quota: `199000 pages`,
    price: 199,
  },
  {
    slug: `collector`,
    name: `Collector`,
    quota: `999000 pages`,
    price: 799,
  },
]

const marketingPlans = plans.concat(serpPlans)

const isBrowser = () => typeof window !== `undefined`

const getDomain = () => window.location.host.replace(/^([^.]+)./, ``)
const getGraphqlURL = () => process.env.REACT_APP_GRAPHQL_URL || `/_graphql/www/`

const makeSettings = () => {
  let settings = {plans, marketingPlans}
  if (isBrowser()) {
    settings = {
      wwwURL: `//www.${getDomain()}/`,
      appURL: `//app.${getDomain()}/`,
      graphqlURL: getGraphqlURL(),
      docsURL: `https://docs.${getDomain()}/`,
      ...settings,
    }
  }
  return settings
}

export const settings = makeSettings()
