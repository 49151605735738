import React from "react"
import Helmet from "react-helmet"
import { config } from "../config"

const Head = ({ title, lang, description }) => (
  <Helmet
    htmlAttributes={{
      lang,
    }}
    title={title}
    titleTemplate={`%s | Spider`}
  >
    <meta name="msapplication-TileColor" content="#ffffff" />
    <meta name="theme-color" content="#ffffff" />
    <meta name="description" content={description || config.description} />
    <meta name="og:title" content={title} />
    <meta name="og:description" content={description || config.description} />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:creator" content="spider.com" />
    <meta name="twitter:title" content="secure.spider.com" />
    <meta name="twitter:description" content="" />
    <link
      rel="preload"
      href="https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap"
      as="style"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap"
    />
  </Helmet>
)

export default Head
