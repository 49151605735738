import filesize from "filesize"
import { formatMoney as money } from "accounting"

export const formatFilesize = (v) => (Number.isInteger(v) ? filesize(v) : v)
export const formatMoneyInt = (v) => money(v, { precision: 0 })
export const formatMoney = (v) => money(v)
export const formatK = (v) => {
  if (v >= 1e3 && v < 1e6) return +(v / 1e3).toFixed(1) + `K`
  if (v >= 1e6 && v < 1e9) return +(v / 1e6).toFixed(1) + `M`
  if (v >= 1e9 && v < 1e12) return +(v / 1e9).toFixed(1) + `B`
  if (v >= 1e12) return +(v / 1e12).toFixed(1) + `T`
  return v
}
